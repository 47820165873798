import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { i18n } from "@/plugins/i18n";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { format, parseISO } from "date-fns";

import { fetchTicket } from "@/api/vendas/vendas.js";

export default async function generateFatura(venda_id, download, print) {
  const venda = await fetchTicket(venda_id);

  return new Promise((resolve, reject) => {
    let content = [];
    let filtro_moeda = "";
    if (venda.moeda_sigla == "G$") {
      filtro_moeda = "guarani";
    } else {
      filtro_moeda = "currency";
    }
    let ticket_topo = {
      table: {
        widths: ["*"],

        body: [
          [
            {
              text:
                venda.lista_preco +
                " - " +
                i18n.tc("global.venda") +
                " " +
                venda.numero,
              fontSize: 12,
              bold: true,
              border: [false, true, false, true],
            },
          ],
          [
            {
              text:
                i18n.tc("global.condicao") +
                " " +
                (venda.condicao_pagamento == 1
                  ? i18n.tc("global.aVista")
                  : i18n.tc("global.aPrazo")),
              fontSize: 10,

              border: [false, false, false, false],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let ticket_data = {
      table: {
        widths: ["*"],
        body: [
          [
            {
              text:
                i18n.tc("global.data") +
                ": " +
                format(parseISO(venda.created_at), "dd/MM/yyyy") +
                "   " +
                i18n.tc("global.hora") +
                ": " +
                format(parseISO(venda.created_at), "H:mm"),
              fontSize: 10,
              bold: true,
              border: [false, false, false, true],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let ticket_tabela = {
      table: {
        widths: ["*", "*", "*", "*"],
        body: [
          [
            {
              text: i18n.tc("global.codigo"),
              fontSize: 10,
              bold: true,
              margin: [0, 5, 0, 0],
              border: [false, false, false, false],
            },
            {
              text: i18n.tc("global.qtd"),
              fontSize: 10,
              bold: true,
              margin: [0, 5, 0, 0],
              border: [false, false, false, false],
            },
            {
              text: i18n.tc("global.preco"),
              fontSize: 10,
              bold: true,
              margin: [0, 5, 0, 0],
              border: [false, false, false, false],
            },
            {
              text: i18n.tc("global.valor"),
              fontSize: 10,
              bold: true,
              margin: [0, 5, 0, 0],
              border: [false, false, false, false],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let qtd_total_items = 0;

    venda.venda_items.forEach((venda_item) => {
      qtd_total_items += Number(venda_item.qtde);
      let item = [
        {
          text: venda_item.id,
          fontSize: 10,

          border: [false, false, false, false],
        },
        {
          text: venda_item.qtde,
          fontSize: 10,

          border: [false, false, false, false],
        },
        {
          text: Vue.filter(filtro_moeda)(venda_item.preco),
          fontSize: 10,

          border: [false, false, false, false],
        },
        {
          text: Vue.filter(filtro_moeda)(
            Number(venda_item.preco) * Number(venda_item.qtde)
          ),
          fontSize: 10,

          border: [false, false, false, false],
        },
      ];
      let item_descricao = [
        {
          text: venda_item.descricao,
          fontSize: 10,

          border: [false, false, false, false],
          margin: [0, 0, 0, 5],
          colSpan: 4,
        },
        {},
        {},
        {},
      ];

      ticket_tabela.table.body.push(item);
      ticket_tabela.table.body.push(item_descricao);
    });

    let ticket_dados_final = {
      table: {
        widths: ["*"],
        body: [
          [
            {
              text:
                i18n.tc("global.qtd") +
                " " +
                i18n.tc("global.item", 2) +
                ": " +
                qtd_total_items,
              fontSize: 10,
              bold: true,
              margin: [0, 5, 0, 5],
              border: [false, true, false, true],
            },
          ],
          [
            {
              text: "TOTAL: " + Vue.filter(filtro_moeda)(venda.total),
              fontSize: 10,
              bold: true,
              alignment: "center",
              margin: [0, 5, 0, 5],
              border: [false, true, false, true],
            },
          ],
          [
            {
              text:
                i18n.tc("global.solicitadoPor") +
                ": " +
                venda.cliente_nome +
                (venda.cliente_ci ? " - " + venda.cliente_ci : ""),
              fontSize: 8,

              margin: [0, 5, 0, 5],
              border: [false, true, false, true],
            },
          ],
          [
            {
              text: "RUC" + ": " + (venda.cliente_ruc ? venda.cliente_ruc : ""),
              fontSize: 8,

              margin: [0, 5, 0, 5],
              border: [false, false, false, true],
            },
          ],
          [
            {
              text:
                "Direccion" +
                ": " +
                (venda.cliente_end_principal
                  ? venda.cliente_end_principal
                  : ""),
              fontSize: 8,

              margin: [0, 5, 0, 5],
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: "*** ESTE BOLETO NO TIENE VALOR FISCAL ***",
              fontSize: 10,
              alignment: "center",
              margin: [0, 5, 0, 5],
              border: [false, false, false, false],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    content.push(ticket_topo, ticket_data, ticket_tabela, ticket_dados_final);

    let docDefinition = {
      info: {
        title: `TicketVenda`,
        author: "PH7",
        subject: "subject of document",
        keywords: "keywords for document",
      },
      // pageSize: layout.obj_folha.pageSize,
      pageSize: "A6",

      content: content,
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    if (print) {
      pdfDocGenerator.print();
    } else if (download) {
      pdfDocGenerator.download(
        `FATURA_${venda.ponto}-${venda.numero.toString().padStart(7, "0")}.pdf`
      );
    } else {
      // pdfDocGenerator.open();
      pdfDocGenerator.getDataUrl((data) => {
        resolve(data);
      });
    }
  });
}
